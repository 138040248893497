<template>
  <div id="editor"></div>
</template>

<script>
import { Editor } from "lv-plat-editor"; //pc端
let editor;
export default {
  mounted() {
    editor = new Editor("editor", "", true);
    this.$ajax
      .post("lv/"+this.$route.query.type+"Answer/detail/" + this.$route.query.id)
      .then((response) => {
        this.$ajax
          .get("lv/work/queryProductById?id=" + response.data.productId)
          .then((res) => {
            if (res.code) {
              let productDataJson = JSON.parse(res.data.productDataJson);
              //   this.$bus.$emit("getLoad", productDataJson);
              editor.clear();
              editor.load(JSON.parse(JSON.stringify(productDataJson)));
            }
          });
      });
  },
};
</script>

<style lang="scss" scoped>
#editor {
  width: 100%;
  height: calc(100vh - 112px);
}
</style>